import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-12 max-w-[336px]" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex gap-3 items-center py-3 border-t border-b border-border-hover" }
const _hoisted_4 = { class: "rounded-full p-2 bg-icon-gradient" }
const _hoisted_5 = { class: "flex flex-row items-center" }
const _hoisted_6 = ["readonly", "value"]
const _hoisted_7 = {
  key: 1,
  class: "text-sm leading-5 font-bold gb-text-text-main truncate shrink-1"
}
const _hoisted_8 = { class: "text-sm leading-5 font-medium gb-text-text-tertiary uppercase whitespace-nowrap ml-2" }
const _hoisted_9 = { class: "flex flex-col gap-2" }
const _hoisted_10 = { class: "text-xs leading-[14px] font-bold uppercase tracking-[0.48px] gb-text-text-secondary" }
const _hoisted_11 = { class: "flex flex-row gap-2 items-center md:grow md:order-2 sm:grow sm:order-2 xs:grow xs:order-2" }
const _hoisted_12 = { class: "md:w-auto sm:w-auto flex-1 truncate text-sm leading-5 font-medium gb-text-text-main px-4 py-3 gb-bg-surface-additional rounded-12" }
const _hoisted_13 = { class: "grid grid-rows-3 p-4 border-2 rounded-16 border-border-base gap-3" }
const _hoisted_14 = { class: "gb-text-text-secondary" }
const _hoisted_15 = { class: "flex gap-2 w-full" }
const _hoisted_16 = {
  class: "w-full",
  target: "_blank",
  href: "https://getblock.io/docs/"
}
const _hoisted_17 = { class: "m-auto" }
const _hoisted_18 = { class: "m-auto" }

import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { GetBlockUIButton, GetBlockUIIcon } from '@getblockui/core';
import { computed, ref } from 'vue';
import { useStore } from '@/store';
import { useGtm } from '@gtm-support/vue-gtm';
import { useNodesStore } from '@/entities/nodes/store';


type ProtocolInfoProtocolType = 'protocol';
type ProtocolInfoNetworkType = 'network';
type ProtocolInfoApiOrAddonType = 'api-addon';
type ProtocolInfosType = [ProtocolInfoProtocolType, ProtocolInfoNetworkType, ProtocolInfoApiOrAddonType];


export default /*@__PURE__*/_defineComponent({
  __name: 'AccessTokenInfoModal',
  emits: ['close', 'renameEndpoint'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const store = useStore();
const gtm = useGtm();
const nodeStore = useNodesStore()

const ProtocolInfo: {[key in ProtocolInfosType[number]]: string} = {
  protocol: nodeStore.getNodeName(store.state.general.accessTokenInfoModal.endpoint.protocol),
  network: store.state.general.accessTokenInfoModal.endpoint.network[0].toUpperCase() + store.state.general.accessTokenInfoModal.endpoint.network.slice(1, store.state.general.accessTokenInfoModal.endpoint.network.length),
  'api-addon': store.state.general.accessTokenInfoModal.endpoint.addon || store.state.general.accessTokenInfoModal.endpoint.api
}

const FieldsProtocolInfo: ProtocolInfosType = ['protocol', 'network', 'api-addon']

const nameInput = ref<HTMLInputElement | null>(null);
const endpointName = ref<string>(store.state.general.accessTokenInfoModal.endpoint.name);
const isRenaming = ref<boolean>(false);
const isRenamingButtonFocus = ref<boolean>(false);
const isCopied = ref<boolean>(false);


const computedUrl = computed<string>(() => {
  if (
    store.state.general.accessTokenInfoModal.endpoint.addon.includes('ws') ||
    store.state.general.accessTokenInfoModal.endpoint.api.includes('ws')
  ) {
    return `wss://go.getblock.io/${store.state.general.accessTokenInfoModal.endpoint._key}`;
  }
  return `https://go.getblock.io/${store.state.general.accessTokenInfoModal.endpoint._key}`;
});

const onBlur = () => {
  document.getSelection()!.removeAllRanges();
};
const handleCopyButtonClick = async (url: string) => {
  if (isCopied.value) return;
  await navigator.clipboard.writeText(url);
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 3000);
};
const changeEndpointName = (event: Event) => {
  endpointName.value = (event.target as HTMLInputElement).value;
};
const startRenaming = () => {
  isRenaming.value = true;
  setTimeout(() => {
    // nextTick(() => (nameInput.value as HTMLInputElement).focus());
    (nameInput.value as HTMLInputElement).focus();
  }, 100);
};
const changeRenamingButtonFocus = (focus: boolean) =>
  (isRenamingButtonFocus.value = focus);

const endRenaming = async () => {
  isRenaming.value = false;
  if (endpointName.value.length !== 0) {
    emit('renameEndpoint', { endpoint: store.state.general.accessTokenInfoModal.endpoint, newName: endpointName.value, namespace: store.state.general.accessTokenInfoModal.endpoint.namespace});
    return;
  }
  endpointName.value = store.state.general.accessTokenInfoModal.endpoint.name;
};
const handleKeyDown = async (event: KeyboardEvent) => {
  if (event.code === 'Enter' || event.code == 'NumpadEnter') {
    await endRenaming()
  }
};
const onFocus = () => {
  (nameInput.value as HTMLInputElement).select();
};
const close = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalTemplate, { onClose: close }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(GetBlockUIIcon), {
                class: "icon-gradient",
                "icon-name": "code-02",
                "color-type": "violet",
                "size-type": "small"
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_Transition, { name: "slide-fade" }, {
                default: _withCtx(() => [
                  (isRenaming.value)
                    ? (_openBlock(), _createElementBlock("input", {
                        key: 0,
                        ref_key: "nameInput",
                        ref: nameInput,
                        class: "bg-transparent w-full max-w-[160px] truncate text-sm leading-5 font-bold gb-text-text-main shrink-1 transition-all",
                        maxlength: "20",
                        readonly: !isRenaming.value,
                        value: endpointName.value,
                        onBlur: onBlur,
                        onChange: endRenaming,
                        onFocus: onFocus,
                        onFocusout: onBlur,
                        onInput: changeEndpointName,
                        onKeydown: handleKeyDown
                      }, null, 40, _hoisted_6))
                    : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(endpointName.value), 1))
                ]),
                _: 1
              }),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(store).state.general.accessTokenInfoModal.endpoint.addon||
                _unref(store).state.general.accessTokenInfoModal.endpoint.api), 1)
            ]),
            (!isRenaming.value)
              ? (_openBlock(), _createBlock(_unref(GetBlockUIIcon), {
                  key: 0,
                  class: "shrink-0 cursor-pointer",
                  "icon-name": "edit-01",
                  "size-type": "small",
                  "color-type": isRenamingButtonFocus.value ? 'tertiary' : 'disable',
                  onClick: startRenaming,
                  onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (changeRenamingButtonFocus(true))),
                  onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (changeRenamingButtonFocus(false)))
                }, null, 8, ["color-type"]))
              : (_openBlock(), _createBlock(_unref(GetBlockUIButton), {
                  key: 1,
                  class: "shrink-0",
                  "color-type": "accent",
                  "size-type": "small",
                  "icon-button": true,
                  onClick: endRenaming
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(GetBlockUIIcon), {
                      "color-type": "color-bg",
                      "icon-name": "check"
                    })
                  ]),
                  _: 1
                }))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('access-token-info-modal.label')), 1),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(computedUrl.value), 1),
              _createVNode(_unref(GetBlockUIButton), {
                "color-type": "secondary",
                "size-type": "medium",
                "icon-button": true,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (handleCopyButtonClick(computedUrl.value)))
              }, {
                default: _withCtx(() => [
                  (!isCopied.value)
                    ? (_openBlock(), _createBlock(_unref(GetBlockUIIcon), {
                        key: 0,
                        "color-type": "secondary",
                        "icon-name": "copy-03"
                      }))
                    : (_openBlock(), _createBlock(_unref(GetBlockUIIcon), {
                        key: 1,
                        "color-type": "success",
                        "icon-name": "check-circle"
                      }))
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(FieldsProtocolInfo, (field) => {
              return _createElementVNode("div", {
                key: field,
                class: "flex justify-between text-sm font-medium"
              }, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t(`access-token-info-modal.protocol-info.${field}`)) + ":", 1),
                _createElementVNode("p", null, _toDisplayString(ProtocolInfo[field]), 1)
              ])
            }), 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("a", _hoisted_16, [
            _createVNode(_unref(GetBlockUIButton), {
              "color-type": "tint",
              "size-type": "medium",
              class: "w-full"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t(`access-token-info-modal.buttons.docs`)), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_unref(GetBlockUIButton), {
            "color-type": "accent",
            "size-type": "medium",
            class: "w-full",
            onClick: close
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t(`access-token-info-modal.buttons.continue`)), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})