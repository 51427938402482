import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import {
  SharedNodesActions,
  SharedNodesMutations,
  SharedNodesState,
} from '@/types/dashboard/sharedNodes/store';
import {
  getCodeSnippets,
} from '@/api/sharedNodes';
import { Node } from '@/types/shared/shared';

export type State = SharedNodesState;
export type Mutations = SharedNodesMutations;
export type Actions = SharedNodesActions;

const state: () => State = () => ({
  sharedEndpoints: [],
  dedicatedEndpoints: [],
  codeSnippets: [],
  codeSnippetsSpecs: {
    protocol: '',
    namespace: 'shared',
  },
  currentNode: {
    protocol: '',
  },
  showTopUpBalanceModal: false,
  showContactBoostRPSModal: false,
  showUpgradePlanModal: false,
});

const mutations: MutationTree<State> & Mutations = {
  setCodeSnippets(state, codeSnippets) {
    state.codeSnippets = codeSnippets;
  },
  setCodeSnippetsSpecs(state, specs) {
    state.codeSnippetsSpecs = specs;
  },
  setShowTopUpBalanceModal(state, show) {
    state.showTopUpBalanceModal = show;
  },
  setShowContactBoostRPSModal(state, show) {
    state.showContactBoostRPSModal = show;
  },
  setShowUpgradePlanModal(state, show) {
    state.showUpgradePlanModal = show;
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async getCodeSnippets({ commit, getters }, { namespaces, protocol, recaptchaToken }) {
    try {
      const codeSnippets = await getCodeSnippets(namespaces, recaptchaToken, protocol);
      commit('setCodeSnippets', Object.keys(codeSnippets.data).map((language) => ({
        language,
        code: codeSnippets.data[language],
      })));

      commit('setCodeSnippetsSpecs', {
        namespace: namespaces.length === 1 ? namespaces[0] : 'all',
        protocol: getters.nodes.find((node: Node) => node.protocol._key === protocol)?.protocol?.name || protocol,
      });
    } catch (e) {
      // TODO: add sharedNodes error
      // const { errorData } = useNotification();
      // commit('setNotificationMessage', { ...errorData('dedicated-order_get-available-protocols'), type: 'error' });
      // commit('setNotificationMessageDisplay', true);
    }
  },
};

// @ts-ignore
const sharedNodes: Module<State, RootState> = {
  state,
  mutations,
  actions,
};

// @ts-ignore
export default sharedNodes;