import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { INotificationMessage } from '@/features/notification/types';

const useNotificationPrivateStore = defineStore('notificationPrivate', () => {
  const notificationMessage = ref<INotificationMessage>({
    title: '',
    description: ''
  });
  const notificationMessageDisplay = ref<boolean>(false);


  return {
    notificationMessage,
    notificationMessageDisplay,
  }
})

export const useNotificationStore = defineStore('notification', () => {
  const privateStore = useNotificationPrivateStore();

  const getNotificationMessage = computed(() => privateStore.notificationMessage);
  const getNotificationDisplay = computed(() => privateStore.notificationMessageDisplay);

  const setNotificationMessage = (notificationMessageData: INotificationMessage) => {
    privateStore.notificationMessage = notificationMessageData
  }
  const setNotificationMessageDisplay = (display: boolean) => {
    privateStore.notificationMessageDisplay = display
  }

  return {
    getNotificationMessage,
    getNotificationDisplay,
    setNotificationMessage,
    setNotificationMessageDisplay
  }
})