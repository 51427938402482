import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "rounded-24 relative bg-[#3d70eb] w-[568px] after:content-[''] after:absolute after:left-0 after:h-full after:w-[40%] after:bg-gradient-to-r after:from-[#79aff9] after:top-0 after:rounded-24 sm:w-full xs:w-full sm:rounded-t-[24px] sm:fixed xs:fixed xs:bottom-0 sm:bottom-0 sm:max-h-[80%] xs:rounded-t-[24px]" }
const _hoisted_2 = { class: "flex items-center m-6 gap-6" }
const _hoisted_3 = { class: "text-text-color-bg z-20 text-xl leading-6 max-w-[170px] font-bold" }
const _hoisted_4 = { class: "flex z-20 ml-auto" }
const _hoisted_5 = ["alt", "src"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["innerHTML"]

import {
  GetBlockUINotification,
  GetBlockUIButton,
  GetBlockUIIcon,
} from '@getblockui/core';
import { GetBlockUiContactForm } from '@getblockui/common';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { useCookie } from '@/composables/useCookie';
import { useI18n } from 'vue-i18n';
import { useDark } from '@vueuse/core';
import { useNotification } from '@/composables/useNotification';
import { IReCaptchaComposition, useReCaptcha } from 'vue-recaptcha-v3';
import { sendFeedback } from '@/api';
import { useGtm } from '@gtm-support/vue-gtm';
import StepScreen from '@/components/onboarding/StepScreen.vue';
import { useResize } from '@/composables/useResize';
import { NameCookie } from '@/types/composables/useCookie';
import { jwtParse } from '@/utils/jwtParse';
import support1 from '@/assets/images/contactModal/newSupport/support-1.png';
import support2 from '@/assets/images/contactModal/newSupport/support-2.png';
import support3 from '@/assets/images/contactModal/newSupport/support-3.png';
import support4 from '@/assets/images/contactModal/newSupport/support-4.png';
import ContactDedicatedManager from '@/components/modals/ContactDedicatedManager.vue';
import AccessTokenInfoModal from '@/components/modals/AccessTokenInfoModal.vue';
import { useAmplitude } from '@/composables/useAmlitude';
import { useMixpanel } from '@/composables/useMixpanel';
import { Endpoint } from '@/types/dashboard/sharedNodes/endpoint';

type Cookie = 'strictly' | 'functional' | 'statistics' | 'marketing';

export interface IArgsFromContactForm {
  subject: 'sales' | 'partnership' | 'support';
  email: string;
  name: string;
  company: string;
  message: string;
  selectedNodes?: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { setCookie, getCookie, deleteCookie, deleteAllCookies } = useCookie();
let { t, locale } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const gtm = useGtm();
const { setUserAmplitude } = useAmplitude();
const { setUserMixpanel } = useMixpanel();

const modifiLocale = computed(() => {
  if (locale.value === 'en-gb' || locale.value === 'en') return 'en';
  return 'cn';
});

const SupportImages: Readonly<string[]> = [
  support1,
  support2,
  support3,
  support4,
] as const;

const { successData } = useNotification();

const { recaptchaLoaded, executeRecaptcha } = useReCaptcha() as IReCaptchaComposition;
const { windowWidth } = useResize();

const notificationData = computed(() => {
  return {
    title: store.state.general.notificationMessage.title,
    description: store.state.general.notificationMessage.description,
  };
});

const isDark = useDark();
const successfullySentMessage = ref<boolean>(false);

const colorMode = computed(() => {
  return isDark.value ? 'dark' : 'light';
});

const closeModal = () => {
  store.commit('setContactModalDisplay', {
    display: false,
    contacts: store.state.general.contactModal.contacts,
  });
};
const closeAccessTokenInfoModal = () => {
  store.commit('setAccessTokenInfoModalDisplay', false);
};
const closeContactDedicatedManager = () => {
  store.commit('setContactModalPaymentManager', {display: false, type: store.state.general.contactModalPaymentManager.type});
};

const isNeededToOpenStepsModal = ref<boolean>(false);

const setLocale = () => {
  locale.value =
    getCookie('locale') === 'cn' || getCookie('locale') === 'zh-cn' ? 'zh-cn' : 'en-gb';
};

const setAccessToken = () => {
  if (getCookie('access_token'))
    store.commit('setAccessToken', getCookie('access_token'));
};

const getPackageKey = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('packageKey')) {
    const packageKey = urlParams.get('packageKey');
    store.commit('setBuyFromWebsite', {
      active: true,
      packageKey,
    });
    router.push('/buy-packages');
  }
};

const getAccountType = () => {
  if (getCookie('account_type'))
    store.commit('setAccountType', getCookie('account_type'));
};

const getAcceptedCookie = (): Cookie[] => {
  const acceptedCookiesCookieStorage: Cookie = getCookie('acceptedCookies') as Cookie;
  if (acceptedCookiesCookieStorage)
    return acceptedCookiesCookieStorage.split(',') as Cookie[];
  return [];
};

const checkAcceptedCookies = () => {
  const acceptedCookies = getAcceptedCookie();
  if (acceptedCookies.length === 0) {
    deleteAllCookies(['isTokenUpdated1']);
    return;
  }
  if (!acceptedCookies.includes('functional')) deleteCookie('locale', '.getblock.io');
  // if (acceptedCookies.includes('statistics')) gtm?.enable(true);
};

const isMessageSent = ref<boolean>(false);

const setUserData = () => {
  if (store.state.accessToken) {
    const userId = jwtParse(store.state.accessToken).sub;
    store.commit('setUserId', userId);
    store.commit('setUserAvatar', userId);
    setUserAmplitude(userId);
    setUserMixpanel(userId);
  }
};

// It is worth adding the ability to export interfaces from the ui-kit
const sendMessage = async (args: IArgsFromContactForm) => {
  try {
    isMessageSent.value = true;
    successfullySentMessage.value = false;
    let { subject, email, name, company, message, selectedNodes } = { ...args };
    let structuredMessage = `${subject.toUpperCase()}`;
    if (company) structuredMessage = structuredMessage + `\n\nUser company: ${company}`;
    if (selectedNodes)
      structuredMessage =
        structuredMessage + `\n\nUser selected nodes: ${selectedNodes.join(',')}`;
    structuredMessage = structuredMessage + `\n\nMessage: ${message}`;
    await recaptchaLoaded();
    const recaptchaToken = await executeRecaptcha('general_sendFeedback');
    await sendFeedback(email, name, structuredMessage, recaptchaToken);
    store.commit('setNotificationMessage', {
      ...successData('general_send-feedback'),
      type: 'high',
    });
    store.commit('setNotificationMessageDisplay', true);
    successfullySentMessage.value = true;
  } catch (e) {
    const { errorData } = useNotification();
    store.commit('setNotificationMessage', {
      ...errorData('general_send-message'),
      type: 'error',
    });
    store.commit('setNotificationMessageDisplay', true);
  } finally {
    isMessageSent.value = false;
    store.commit('setContactModalDisplay', {
      display: false,
      contacts: store.state.general.contactModal.contacts,
    });
  }
};

onMounted(async () => {
  if (localStorage.getItem('isTokenUpdated') !== 'true') {
    const domain = {
      'https://account.getblock.dev': '.getblock.dev',
      'https://account.getblock.io': '.getblock.io',
      'http://localhost:8080': '.localhost',
    };
    // @ts-ignore
    deleteCookie('access_token', domain[window.location.origin]);
    deleteCookie('access_token');
    localStorage.setItem('isTokenUpdated', 'true');
  }
  setAccessToken();
  setUserData();
  await router.isReady();
  checkAcceptedCookies();
  getAccountType();
  getPackageKey();
  setLocale();
});

const isDataLoading = computed(() => store.state.general.loading);

const handleRenameEndpoint = async ({
                                      endpoint,
                                      newName,
                                      namespace,
                                      dedicatedNodeId,
                                    }: {
  endpoint: Endpoint;
  newName: string;
  namespace: 'shared' | 'dedicated';
  dedicatedNodeId: string;
}) => {
  await recaptchaLoaded();
  const recaptchaToken = await executeRecaptcha('endpoints_rename');
  if (namespace === 'shared') {
    store.dispatch('renameSharedEndpoint', {
      name: newName,
      id: endpoint?._key,
      recaptchaToken,
    });
    return;
  }
  store.dispatch('renameDedicatedEndpoint', {
    name: newName,
    accessTokenId: endpoint._key,
    dedicatedNodeId,
    recaptchaToken,
  });
};

// watch(
//   () => [isNeededToOpenStepsModal.value, isDataLoading.value],
//   async () => {
//     if (store.state.sharedNodes.endpoints.length !== 0) {
//       localStorage.setItem('isOnboardingPassed', 'true');
//     }
//     if (!isDataLoading.value) {
//       if (isNeededToOpenStepsModal.value) {
//         store.commit('setShowOnboarding', {
//           stepsModal: true,
//         });
//         localStorage.setItem('isOnboardingPassed', 'true');
//         isNeededToOpenStepsModal.value = false;
//       }
//
//       if (localStorage.getItem('isOnboardingPassed') !== 'true') {
//         await router.push('/');
//         store.commit('setShowOnboarding', {
//           stepsModal: true,
//         });
//         localStorage.setItem('isOnboardingPassed', 'true');
//       }
//     }
//   },
// );

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex h-full min-h-screen", {
      'bg-bg-additional dark:bg-bg-dark-additional': !_unref(route).path.includes(
        '/dedicated-node-create'
      ),
      'bg-bg-base dark:bg-bg-dark-base': _unref(route).path.includes('/dedicated-node-create'),
    }])
  }, [
    _createVNode(_component_router_view, { name: "AuthLeftSide" }),
    _createVNode(_component_router_view),
    _withDirectives(_createElementVNode("div", {
      id: "contactModal",
      class: "contactModal",
      onClick: _withModifiers(closeModal, ["self"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)('contactModal.title')), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(SupportImages, (support, index) => {
              return _createElementVNode("div", {
                key: support,
                class: _normalizeClass([
                index === 0
                  ? '-right-[60px]'
                  : index === 1
                  ? '-right-10'
                  : index === 2
                  ? '-right-5'
                  : index === 3
                  ? ''
                  : ''
              , "bg-[#ecf3fe] rounded-full relative h-12 w-12 overflow-hidden border-2 border-[#3d70ed]"])
              }, [
                _createElementVNode("img", {
                  height: "48",
                  width: "48",
                  alt: `support-${index + 1}`,
                  src: support
                }, null, 8, _hoisted_5)
              ], 2)
            }), 64))
          ]),
          _createVNode(_unref(GetBlockUIButton), {
            class: "shrink-0",
            "color-type": "secondary",
            "icon-button": "",
            "size-type": "medium",
            onClick: closeModal
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(GetBlockUIIcon), {
                "color-type": "main",
                "icon-name": "x-close"
              })
            ]),
            _: 1
          })
        ]),
        (_openBlock(), _createBlock(_unref(GetBlockUiContactForm), {
          id: "contactModal",
          key: _unref(store).state.general.contactModal.contacts,
          "color-mode": colorMode.value,
          locale: modifiLocale.value,
          loading: isMessageSent.value,
          "place-open-modal": _unref(store).state.general.contactModal.contacts,
          "successfully-sent-message": successfullySentMessage.value,
          onSend: sendMessage
        }, null, 8, ["color-mode", "locale", "loading", "place-open-modal", "successfully-sent-message"]))
      ])
    ], 512), [
      [_vShow, _unref(store).state.general.contactModal.display]
    ]),
    (_unref(store).state.general.showOnboarding.stepsModal && _unref(windowWidth) >= 1024)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(StepScreen)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_unref(GetBlockUINotification), {
      class: "max-w-[480px] fixed bottom-8 right-8 z-[15] sm:bottom-[92px] sm:max-w-[100%] sm:left-4 sm:right-4 sm:justify-between sm:w-[calc(100%-32px)] xs:bottom-[92px] xs:left-4 xs:right-4 xs:justify-between xs:w-[calc(100%-32px)] xs:max-w-[100%]",
      "data-testid": "notification",
      "info-icon": 
        _unref(store).state.general.notificationMessage.type === 'attention' ||
        _unref(store).state.general.notificationMessage.title ===
          _ctx.$t('notification.error.authentication.sign-in')
      ,
      "main-type": _unref(store).state.general.notificationMessage.type,
      onDeleteNotification: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).commit('setNotificationMessageDisplay', false)))
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(notificationData.value.title), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: notificationData.value.description
        }, null, 8, _hoisted_7)
      ]),
      _: 1
    }, 8, ["info-icon", "main-type"]), [
      [_vShow, _unref(store).state.general.showNotificationMessage]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(ContactDedicatedManager, { onCloseModal: closeContactDedicatedManager })
    ], 512), [
      [_vShow, _unref(store).state.general.contactModalPaymentManager.display]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      (_openBlock(), _createBlock(AccessTokenInfoModal, {
        key: _unref(store).state.general.accessTokenInfoModal.endpoint._key,
        endpoint: _unref(store).state.general.accessTokenInfoModal.endpoint,
        onRenameEndpoint: handleRenameEndpoint,
        onClose: closeAccessTokenInfoModal
      }, null, 8, ["endpoint"]))
    ], 512), [
      [_vShow, _unref(store).state.general.accessTokenInfoModal.display]
    ])
  ], 2))
}
}

})