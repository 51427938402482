import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "xl:rounded-24 lg:rounded-24 md:rounded-24 sm:rounded-24 relative bg-[#3d70eb] w-[480px] after:content-[''] after:absolute after:left-0 after:h-full after:w-[40%] after:bg-gradient-to-r after:from-[#79aff9] after:top-0 after:rounded-24 sm:w-full xs:w-full sm:rounded-t-[24px] sm:fixed xs:fixed xs:bottom-0 sm:bottom-0 sm:max-h-[80%] xs:rounded-t-[24px]" }
const _hoisted_2 = { class: "flex justify-between p-6 items-center relative z-20 ml-auto" }
const _hoisted_3 = { class: "flex flex-col p-6 xl:rounded-24 lg:rounded-24 md:rounded-24 sm:rounded-t-24 xs:rounded-t-[24px] gap-6 relative z-20 xs:z-40 gb-bg-bg-base" }
const _hoisted_4 = { class: "text-center leading-6 font-medium text-lg gb-text-text-main" }
const _hoisted_5 = { class: "flex items-center xs:flex-col xs:gap-2" }
const _hoisted_6 = { class: "flex gap-4 items-center" }
const _hoisted_7 = { class: "rounded-full bg-[#EBF3FF] w-12 h-12 flex items-center justify-center overflow-hidden" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "leading-6 font-bold text-lg gb-text-text-main" }
const _hoisted_10 = {
  target: "_blank",
  href: "https://t.me/Wilfried_GetBlock",
  class: "ml-auto xs:w-full cursor-pointer hover:opacity-60"
}
const _hoisted_11 = { class: "h-11 flex gap-2 leading-5 bg-[#00A3FF] rounded-12 items-center justify-center p-4" }
const _hoisted_12 = { class: "leading-5 font-medium text-text-color-bg" }
const _hoisted_13 = { class: "flex gap-3 items-center" }
const _hoisted_14 = { class: "leading-5 font-medium text-text-secondary" }
const _hoisted_15 = { class: "font-bold text-xs text-[#9D9DA3]" }
const _hoisted_16 = {
  key: 0,
  class: "capitalize"
}
const _hoisted_17 = { class: "text-[#9D9DA3]" }
const _hoisted_18 = {
  key: 0,
  class: "capitalize"
}
const _hoisted_19 = {
  key: 0,
  class: "m-auto font-medium"
}
const _hoisted_20 = ["innerHTML"]

import {
  GetBlockUIButton,
  GetBlockUIIcon,
  GetBlockUIInput,
  GetBlockUILoader,
  GetBlockUITextArea,
} from '@getblockui/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import support4 from '@/assets/images/contactModal/newSupport/support-4.png';
import { sendFeedback } from '@/api';
import { IReCaptchaComposition, useReCaptcha } from 'vue-recaptcha-v3';
import { useStore } from '@/store';
import { useNotification } from '@/composables/useNotification';
import { useGtm } from '@gtm-support/vue-gtm';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactDedicatedManager',
  emits: ['closeModal'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const store = useStore();
const { recaptchaLoaded, executeRecaptcha } = useReCaptcha() as IReCaptchaComposition;
const { successData } = useNotification();
const gtm = useGtm();


const emits = __emit

const telegram = ref<string>('');
const message = ref<string>('');
const isMessageSending = ref<boolean>(false);
const telegramError = ref<boolean>(false);
const textAreaError = ref<boolean>(false);

const checkValidData = () => {
  if (!telegram.value.trim()) telegramError.value = true
}

const formationStructuredMessage = () => {
  let structuredMessage = store.state.general.contactModalPaymentManager.type === 'dedicated' ? 'DEDIC CONFIGURATOR' : 'PURCHASE'
  structuredMessage = structuredMessage + `\n\n Telegram: ${telegram.value}`;
  structuredMessage = structuredMessage + `\n\n Message: ${message.value}`;
  return structuredMessage
}

const send = async () => {
  if (isMessageSending.value) return
  checkValidData()
  if (telegramError.value || textAreaError.value) return
  try {
    isMessageSending.value = true
    const structuredMessage = formationStructuredMessage();
    await recaptchaLoaded();
    const recaptchaToken = await executeRecaptcha('general_sendFeedback');
    await sendFeedback(telegram.value, store.state.auth.userID, structuredMessage, recaptchaToken);
    store.commit('setNotificationMessage', {
      ...successData('general_send-feedback'),
      type: 'high',
    });
    store.commit('setNotificationMessageDisplay', true);
  } catch (e) {
    const { errorData } = useNotification();
    store.commit('setNotificationMessage', {
      ...errorData('general_send-message'),
      type: 'error',
    });
    store.commit('setNotificationMessageDisplay', true);
  } finally {
    isMessageSending.value = false
    store.commit('setContactModalPaymentManager', {display: false, type: store.state.general.contactModalPaymentManager.type});
  }
}

const closeModal = () => {
  emits('closeModal')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "contactModal",
    onClick: _withModifiers(closeModal, ["self"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock("p", {
          key: _unref(store).state.general.contactModalPaymentManager.type,
          class: "max-w-[300px] text-xl leading-6 font-bold text-text-color-bg"
        }, _toDisplayString(_unref(t)(`contact-dedicated-manager.title-modal.${_unref(store).state.general.contactModalPaymentManager.type}`)), 1)),
        _createVNode(_unref(GetBlockUIButton), {
          "icon-button": "",
          "color-type": "white",
          "size-type": "medium",
          onClick: closeModal
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(GetBlockUIIcon), {
              "color-type": "action",
              "icon-name": "x-close"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)('contact-dedicated-manager.title')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                width: "48",
                height: "48",
                alt: `support-3`,
                src: _unref(support4)
              }, null, 8, _hoisted_8)
            ]),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)(`contact-dedicated-manager.manager`)), 1)
          ]),
          _createElementVNode("a", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_unref(GetBlockUIIcon), {
                "color-type": "color-bg",
                "icon-name": "telegram-multi"
              }),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('contact-dedicated-manager.buttons.telegram')), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-0.5 gb-bg-surface-separator w-full" }, null, -1)),
          _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(t)('contact-dedicated-manager.or')), 1),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "h-0.5 gb-bg-surface-separator w-full" }, null, -1))
        ]),
        _createVNode(_unref(GetBlockUIInput), {
          "input-value": telegram.value,
          "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((telegram).value = $event)),
          "icon-name": "telegram-multi",
          "input-name": "telegram",
          placeholder: _unref(t)('contact-dedicated-manager.input.placeholder'),
          error: telegramError.value,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (telegramError.value = false)),
          onKeydown: _withKeys(send, ["enter"]),
          "status-icon": "none"
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)('contact-dedicated-manager.input.label')), 1)
          ]),
          hint: _withCtx(() => [
            (telegramError.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_unref(t)('contact-dedicated-manager.input.error')), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["input-value", "placeholder", "error"]),
        _createVNode(_unref(GetBlockUITextArea), {
          "input-name": message.value,
          "onUpdate:inputName": _cache[2] || (_cache[2] = ($event: any) => ((message).value = $event)),
          "input-value": message.value,
          "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((message).value = $event)),
          error: textAreaError.value,
          onInput: _cache[4] || (_cache[4] = ($event: any) => (textAreaError.value = false)),
          onKeydown: _withKeys(_withModifiers(send, ["ctrl"]), ["enter"]),
          placeholder: _unref(t)('contact-dedicated-manager.text-aria.placeholder')
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)('contact-dedicated-manager.text-aria.label')), 1)
          ]),
          hint: _withCtx(() => [
            (textAreaError.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(t)('contact-dedicated-manager.text-aria.error')), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["input-name", "input-value", "error", "onKeydown", "placeholder"]),
        _createVNode(_unref(GetBlockUIButton), {
          "color-type": "tint",
          onClick: send
        }, {
          default: _withCtx(() => [
            (!isMessageSending.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_unref(t)('contact-dedicated-manager.buttons.send')), 1))
              : (_openBlock(), _createBlock(_unref(GetBlockUILoader), {
                  key: 1,
                  "color-type": "tint",
                  class: "m-auto"
                }))
          ]),
          _: 1
        }),
        _createElementVNode("p", {
          class: "text-xs text-text-secondary text-center leading-[18px]",
          innerHTML: _unref(t)('contact-dedicated-manager.footer')
        }, null, 8, _hoisted_20)
      ])
    ])
  ]))
}
}

})